.header {
  background: white;
  width: 100%;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  border-radius: 29px;
}
.header__logo {
  width: 190px;
  height: 37.15px;
  gap: 0px;
  opacity: 0px;
}
.header__icons {
  font-size: 15.71px;
}
.header__ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-left: 150px;
}
.header__ul > li {
  font-size: 12.75px;
  font-weight: 700;
  line-height: 14.16px;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 30px;
}
.header__icons {
  font-size: 12.75px;
  font-weight: 700;
  line-height: 14.16px;
  margin-right: 5px;
}
.header__end {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.header__end > .header__icons {
  font-weight: 600;
  font-size: 11.61px;
}
.header__end > div {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.header__menu {
  display: none;
  font-size: 35px;
  margin-left: auto;
}

@media all and (max-width: 768px) {
  .header__menu {
    display: block;
    font-size: 35px;
  }
  .header__ul {
    display: none;
  }
  .header__end {
    display: none;
  }
}
