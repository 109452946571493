.footer {
  background: #669933;
  height: 344px;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  color: white;
}
.footer__start {
}
.footer__start > img {
  height: 40px;
  object-fit: cover;
}
.footer__start__socials {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.footer__start__socials > img {
  height: 16px;
  object-fit: cover;
  margin-right: 25px;
}
.footer__start__app {
  margin-top: 50px;
}
.footer__start__app > h1 {
  font-size: 15px;
  font-weight: 700;
  line-height: 11.67px;
  text-align: left;
}
.footer__start__app > span {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.footer__start__app > span > img {
  width: 123px;
  height: 40.74px;
  border-radius: 4.92px 0px 0px 0px;
}
.footer__start__app > span > img:last-of-type {
  margin-left: 10px;
}
.footer__end {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-left: auto;
  width: 70%;
}
.footer__end__block {
  width: 30%;
}
.footer__end__block > h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
.footer__end__block > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 10px 0;
}
