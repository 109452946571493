.home {
  background: white;
}
.home__main {
  min-height: 100vh;
}

.home__section__one {
  background: #fff6ef;
  padding: 1.5% 15%;
  width: 100%;
  height: 815px;

  border-radius: 0px 0px 120px 120px;
}
.home__section__two {
  width: 100%;
  height: 815px;
}
.home__section__one__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.home__section__one__main__start {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.home__section__one__main__start > button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 28.5px;
  border-radius: 24px;
  border: 0.75px solid #669933;
  background: transparent;
  color: #669933;
}
.home__section__one__main__start > button:last-of-type {
  background: #669933;
  color: white;
  width: 194px;
  height: 48px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 68px;
}
.home__section__one__main__start > h1 {
  font-size: 55px;
  font-weight: 800;
  line-height: 68px;
  text-align: left;
}
.home__section__one__main__start > span {
  display: flex;
  gap: 30px;
}
.home__section__one__main__start > span > h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.02px;
  text-align: left;
  color: #f58634;
}
.home__section__one__main__start > span > h1:last-of-type {
  color: black;
}
.home__section__one__main__start > p {
  font-size: 15px;
  font-weight: 300;
  line-height: 25.02px;
  text-align: left;
}

.home__section__one__main__end {
  width: 40%;
  background: url("/src/assets/home__section__one__bg.png");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 17px;
}
.home__section__one__main__end > img {
  width: 296.17px;
  height: 400px;
}
.home__section__two {
  padding: 1.5% 15%;
  width: 100%;
  margin-top: -100px;
}
.home__section__two__start {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  padding: 30px 30px;
  background: url("/src/assets/home__section__two__banner.png");
  background-size: cover;
  border-radius: 26px;
}
.home__section__two__start__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 15px;
  background: #ffffff99;
  border-radius: 22px;
  width: 292px;
  height: 115px;
}
.home__section__two__start__block > h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: white;
}
.home__section__two__start__block > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.home__section__two__end {
  font-size: 24px;
  font-weight: 700;
  line-height: 68px;
  text-align: left;
  margin-bottom: 20px;
}
.home__section__two__end > h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 68px;
  text-align: left;
  margin: 20px 0;
}
.home__section__two__end__section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
}

.home__section__two__end__one {
  width: 25%;
  border-radius: 22.43px;
  background: url("/src/assets/home__section__one__bg.png");
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.home__section__two__end__one > div {
  width: 322px;
  min-height: 423px;
  border-radius: 15px;
  background: #ffffffcc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__section__two__end__one > div > img {
  width: 297.2px;
  height: 176.56px;
}
.home__section__two__end__one > div > h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.home__section__two__end__one > div > p {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.68px;
  text-align: left;
}
.home__section__two__end__one > div > span {
  display: flex;
  gap: 30px;
}
.home__section__two__end__one > div > span > h3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 68px;
  text-align: left;
}
.home__section__two__end__two {
  width: 73%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home__section__two__end__two > div {
  width: 19%;
  margin: 10px 0;
}
.home__section__two__end__two > div > img {
  object-fit: cover;
  height: 147.19px;
  width: 100%;
}
.home__section__two__end__two > div > h1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.home__section__two__end__two > div > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__two__end__two > div > span > h3 {
  font-size: 12px;
  line-height: 20px;
  color: var(--seconday-text-color);
}
.home__section__two__end__two > div > span > h4 {
  font-size: 12px;
  line-height: 20px;
  color: var(--seconday-text-color);
}
.home__section__three {
  display: flex;
  justify-content: space-between;
  padding: 1.5% 15%;
}
.home__section__three__one {
  width: 64%;
}
.home__section__three__one > h1 {
  margin-bottom: 30px;
}
.home__section__three__one > img {
  object-fit: cover;
  width: 100%;
  height: 700px;
  border-radius: 20px;
}
.home__section__three__two {
  width: 32%;
}
.home__section__three__two > h1 {
  margin-bottom: 20px;
}
.home__section__three__two > img {
  width: 100%;
  height: 700px;
  border-radius: 20px;
}
.home__section__four {
  display: flex;
  flex-direction: column;
  padding: 1.5% 15%;
}
.home__section__four__heading {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__four__heading__icon {
  margin-left: 10px;
  color: #669933;
}
.home__section__four__heading > h2 {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.home__section__four__display {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home__section__four__display > div {
  width: 10%;
  margin: 10px 0;
}
.home__section__four__display > div > img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 10px;
}
.home__section__four__display > div > h1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.home__section__four__display > div > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__four__display > div > span > h3 {
  font-size: 12px;
  line-height: 20px;
  color: var(--seconday-text-color);
}
.home__section__four__display > div > span > h4 {
  font-size: 12px;
  line-height: 20px;
  color: var(--seconday-text-color);
}
.home__section__five {
  background: #fff6ef;
}
.home__section__four__alt {
  display: flex;
  flex-direction: column;
  padding: 1.5% 15%;
}
.home__section__four__alt__heading {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__four__alt__heading__icon {
  margin-left: 10px;
  color: #669933;
}
.home__section__four__alt__heading > h2 {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.home__section__four__alt__display {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home__section__four__alt__display > div {
  width: 23%;
  margin: 10px 0;
  display: flex;
  gap: 10px;
}
.home__section__four__alt__display > div > img {
  object-fit: cover;
  height: 200px;
  width: 150px;
  border-radius: 10px;
}
.home__section__four__alt__display > div > div > h1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
}
.home__section__four__alt__display > div > div > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__section__four__alt__display > div > div > span > h3 {
  font-size: 12px;
  line-height: 20px;
  color: var(--seconday-text-color);
}
.home__section__four__alt__display > div > div > span > h4 {
  font-size: 12px;
  line-height: 20px;
  color: var(--seconday-text-color);
}
.home__section__four__alt__display > div > div > h4 {
  font-size: 16px;
  line-height: 20px;
  color: #669933;
  margin-top: 20px;
}

@media all and (max-width: 768px) {
}
